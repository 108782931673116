export class ConfigServices {
  static BASE_URL = "https://backend.demmistotechnologies.com/";
 // static BASE_URL = "http://localhost:5000/";
  static BASE_PATH_EVENTS = "events/";
  static BASE_PATH_BLOGS = "blogs/";
  static BASE_PATH_UPEVENTS = "upevents/";
  static BASE_PATH_CAREERS = "careers/";
  static BASE_PATH_INQUIRY = "inquiry/";
  static BASE_PATH_WORKSHOP = "workshop/";
  static BASE_PATH_STUDENTS = "register/";
  static BASE_PATH_AUTHENTICATION = "employees/";
}
