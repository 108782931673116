// StaffReducer.js
import { ActionTypes } from "./ActionTypes";
import { ConfigServices } from "../Services/Config";
console.log(ConfigServices.BASE_PATH_STUDENTS + "true");
const initialState = {
  [ConfigServices.BASE_PATH_EVENTS]: [],
  [ConfigServices.BASE_PATH_CAREERS]: [],
  [ConfigServices.BASE_PATH_BLOGS]: [],
  [ConfigServices.BASE_PATH_INQUIRY]: [],
  [ConfigServices.BASE_PATH_WORKSHOP]: [],
  [ConfigServices.BASE_PATH_UPEVENTS]: [],
  [ConfigServices.BASE_PATH_STUDENTS + "true"]: [],
  [ConfigServices.BASE_PATH_STUDENTS + "false"]: [],
};

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD:
      if (action.payload.index === -1) {
        return {
          ...state,
          [action.payload.url]: [
            ...state[action.payload.url],
            action.payload.loaded,
          ],
        };
      } else {
        const updatedData = [...state[action.payload.url]];
        updatedData[action.payload.index] = action.payload.loaded;
        return { ...state, [action.payload.url]: updatedData };
      }

    case ActionTypes.VIEW:
      return { ...state, [action.payload.url]: action.payload.List };

    case ActionTypes.REMOVE:
      return {
        ...state,
        [action.payload.url]: state[action.payload.url].filter(
          (item) => item._id !== action.payload.loaded._id
        ),
      };

    default:
      return state;
  }
};
