import axios from "axios";
import { ConfigServices } from "./Config";

const AxiosClient = axios.create({
    baseURL: ConfigServices.BASE_URL,

});
AxiosClient.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers['Authorization'] =  token
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
export default AxiosClient;